<template>
    <div :class="['navbar-area', {'is-sticky': isSticky}]">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container">
                <router-link class="navbar-brand" to="/">
                    <img src="../../assets/img/logo-cambiositalo.png" alt="logo">
                </router-link>
                <div
                    class="navbar-toggler"
                    @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                    v-bind:class="{ 'active': button_active_state }"
                    v-on:click="button_active_state = !button_active_state"
                >
                    <i class="fas fa-bars"></i>
                    <i class="fas fa-times"></i>
                </div>
                <div class="collapse navbar-collapse" :class="{ toggler: active }">
                    <ul class="navbar-nav">
                        <li class="nav-item" v-for="(item, key) in menu" :key="key">
                            <a :href="'#' + item.link" @click="scrollInto(item.link)" :class="'nav-link' + (lastParam === item.link ? ' active' : '')">
                                {{ item.name }}
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="others-option">
                    <div class="d-flex align-items-center">
                        <div class="option-item">
                            <a href="https://app.cambiositalo.com/login" target="_blank" class="login-btn">
                                <i class='bx bx-log-in'></i> 
                                Iniciar Sesion
                            </a>
                        </div>
                        <div class="option-item">
                            <a href="https://app.cambiositalo.com/register" target="_blank" class="default-btn">
                                <i class='bx bxs-user'></i> 
                                Registrate
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data(){
        return {
            lastParam: '',
            isSticky: false,
            active: false,
            button_active_state: false,
            country: 'PER',
            menu: [
                { name: 'Inicio', link: 'home' },
                { name: 'Metodos de Pago', link: 'payments' },
                { name: 'Proceso de Envío', link: 'process' },
                { name: 'Nuestra App', link: 'download' }
            ]
        }
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            that.isSticky = scrollPos >= 100;
        });
        if (window.location.hash) {
            const url = window.location.href;
            const lastParam = url.split("#").slice(-1)[0];
            this.scrollInto(lastParam);
        }
    },
    methods: {
        scrollInto(elementId) {
            const section = document.querySelector(`#${elementId}`);
            section.scrollIntoView({
                behavior: 'smooth'
            });
            this.lastParam = elementId;
        }
    }
}
</script>