<template>
    <div class="value-trade-area">
        <div class="container">
            <div class="value-trade-slides">
                <carousel
                    :autoplay="2000"
                    :wrap-around="true"
                    :breakpoints="breakpoints"
                    :settings="settings"
                >
                    <slide v-for="coin in coins" :key="coin.id">
                        <div class="single-value-trade-box">
                            <p>
                                <img :src="require(`../../assets/img/country/${ coin.country_send ?? '000' }.png`)" :alt="coin.country_send"/>
                                <span class="symbol">{{ coin.currency_send }} / {{ coin.currency_receiver }}</span>
                                <img :src="require(`../../assets/img/country/${ coin.country_receiver ?? '000' }.png`)" :alt="coin.country_receiver"/>
                                <span class="price">{{ Number(coin.value).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 4}) }}</span>
                                <!--span
                                    class="trending"
                                    :class="[
                                        coin.price_change_percentage_24h > 0 ? 'text-default' : 'text-danger'
                                    ]"
                                >
                                    <i class="one fas fa-caret-up"></i> 
                                    <i class="two fas fa-caret-down"></i>
                                    {{ coin.price_change_percentage_24h }}
                                </span-->
                            </p>
                        </div>
                    </slide>

                    <template #addons>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'ValueTrade',
    components: {
        Carousel,
        Slide
    },
    data: () => ({
        // carousel settings
        settings: {
            snapAlign: 'center',
        },
        breakpoints: {
            0: {
                itemsToShow: 2,
                snapAlign: 'left',
			},
            576: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 6,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 7,
                snapAlign: 'center',
            },
            1550: {
                itemsToShow: 8,
                snapAlign: 'center',
            }
        },
        coins: [],
    }),
    mounted(){
        this.getCurrencies();
    },
    methods: {
        async getCurrencies() {
            const res = await fetch('https://web.icoinapp.com/api/currencies/all')
            let coins = await res.json();
            this.coins = coins.rates;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.getCurrencies, 600000);
        }
    },
    unmounted() {
        clearTimeout(this.timeout);
    }
})
</script>